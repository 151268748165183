import { Box, Grid, GridItem, Heading, Link, SimpleGrid, Text, Container } from "@chakra-ui/react";
import React from "react";
import parser from "html-react-parser";

const EmbedBlock = ({ page, block }) => {

    let height = '60vh';
    if (block.entry.height && block.entry.height != '') {
        height = block.entry.height;
    }

    return (
        <Box className="category-wrapper" pt={10} pb={10}>
            <Container maxW={'5xl'}>
                <div class="experiences-header">
                    <Heading
                        as={'h2'}
                        fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                        textAlign={'center'}
                        lineHeight={'110%'}
                        color="#17325B">
                        {block.title}
                    </Heading>
                </div>

                {block.entry.embed != null ? (
                    <Box className="content-block" bg="white" p={10} roundedBottomRight={20} height={height}>
                        {parser(block.entry.embed)}
                    </Box>
                ) : null}
            </Container>
        </Box>
    )
};

export default EmbedBlock;